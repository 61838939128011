import React, { Component } from 'react'
import "../scss/Navbar.scss"

import logo from "../imgs/banner-dark.png"

//react-scroll
import { Link } from 'react-scroll';

import LngButton from "./LngButton";

class Navbar extends Component {
    state = { clicked: false};
    handleClick = () => {
        this.setState({clicked:!this.state.clicked})
    }

    render(){
  return (
    <>
        <nav>
            <a>
                <img style={{width: 250 }} src={logo} alt='logo' />
            </a>
            
            <div>
                <ul id='navbar' className={this.state.clicked ? "#navbar active" : "#navbar"}>
                    <li><Link to="slideShow" spy="true" smooth="true" offset={50} duration={500}>Home</Link></li>
                    <li><Link to="aboutUs" spy="true" smooth="true" offset={50} duration={500}>About</Link></li>
                    <li><Link to="service" spy="true" smooth="true" offset={50} duration={500}>Service</Link></li>
                    <li><Link to="OurProgrammers" spy="true" smooth="true" offset={50} duration={500}>Resource</Link></li>
                    <li><Link to="appCollectionSlider" spy="true" smooth="true" offset={50} duration={500}>Collections</Link></li>
                    <li><Link to="contact" spy="true" smooth="true" offset={50} duration={500}>Contact</Link></li>
                    <li><LngButton/></li>
                </ul>
            </div>
   
            <div id='mobile' onClick={ this.handleClick }>
                <i id='bar' className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                
            </div>
        </nav>
    </>
  )
}
}

export default Navbar